<template>
  <div>
    <div class="container">
      <div class="breadcrumb">
        <p class="topic_path m-0">
          <router-link :to="{ name: 'AccountSettings'}" class="ashen-link">{{ $t("account.default.pageTitle") }}</router-link>
          <i class="fas fa-angle-right form-control-color"></i> {{ $t("account.deleteAccount.pageTitle") }}
        </p>
      </div>
    </div>

    <div class="container delete-account-container m-b-110">
      <div class="card p-1 text-first">
        <div class="card-body mx-auto">

          <div class="row">
            <h2 class="mb-4 fw-bold">{{ $t("account.deleteAccount.pageTitle") }}</h2>
            <div class="col">
              <label for="inputCurrentPassword">{{ $t("account.deleteAccount.currentPassword") }}</label>
              <input id="inputCurrentPassword" v-model="password" class="form-control" type="password">
              <div v-show="errors['authenticationPassword']" class="form-error">{{ errors['authenticationPassword'] }}</div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col">
              <p class="perform-account-deletion">{{ $t("account.deleteAccount.associateInfoDelete") }}<br><br></p>
              <p v-html="$t('account.deleteAccount.performAccountDeletion')">
              </p>
            </div>
          </div>

          <div class="row mt-4 text-center update-btn-div">
            <div class="col-sm-6 order-1 order-sm-0 mt-3 mt-sm-0">
              <button class="btn btn-light btn-lg btn-block btn-cancel" v-on:click="doCancel">{{ $t("account.editWithoutPassword.btnCancel") }}</button>
            </div>
            <div class="col-sm-6 order-0 order-sm-1">
              <button class="btn btn-dark btn-lg btn-block btn-delete" v-on:click="doDelete">{{ $t("account.deleteAccount.btnDelete") }}</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Common from '../assets/jsipclient/common'
import {SetDeleteUser} from "../assets/jsipclient/index";

export default {
  name: "DeleteAccount",
  data() {
    return {
      password: '',
      errors: []
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    /**
     * Validation and registration of input values
     * 1,2,3 is check
     *
     * @param commit
     * @param {Credentials} payload
     * @returns {Promise<Credentials>}
     */
    async doDelete(step) {
      this.errors = [];
      const userId = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        // if success, empty array
        const params = new SetDeleteUser()
        params.authenticationPassword = this.password
        const jsonObject = await api.usersUserIdDeletePostAsync(userId, params)

        Common.showToast(this.$t('account.deleteAccount.toastSuccessDeleteAccount'))
        this.$router.push({name: 'Login'})

      } catch (error) {
        const res = error.response.data
        if (res.validationErrors !== null) {
          this.errors = Common.getErrorList(error.response, this.errors)
        }
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    async doCancel(step) {
      this.$router.push({name: 'AccountSettings'})
    },
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "auto"})
  }
}
</script>

<style lang="scss" scoped>

label[for="inputCurrentPassword"] {
  line-height: 30px;
}

input {
  background-color: #D9E5F7;
  height: 48px;
}

.perform-account-deletion {
  padding-top: 20px;
}

.tournament-info-breadcrumb {
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 17px;
  position: relative;
  font-size: 13px;
  font-weight: bold;
  border-bottom: 1px solid #D3D8EA;
}

.tournament-info-breadcrumb a {
  color: #333333;
}

.delete-account-container {
  padding-left: 0;
  padding-right: 0;
}

input {
  background-color: #D9E5F7;
  border: 1px solid #EFF2F7;
  height: 48px;
}

label {
  font-size: 13px;
  line-height: 30px;
  color: #333333;
}

.btn-delete {
  color: #FFFFFF;
  background-color: #333333;
  border-color: #2663D1;
  font-size: 16px;
  font-weight: bold;
}

.btn-cancel {
  color: #888888;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  font-size: 16px;
  font-weight: bold;
}

.btn-delete, .btn-cancel {
  display: block;
  width: 100%;
}

.update-btn-div {
  margin-bottom: 20px;
}

.card {
  border: none;
  border-radius: 0;
}

@media only screen and (max-width: 576px) {

  .tournament-info-breadcrumb {
    margin-top: -40px;
  }

  .delete-account-container {
    font-weight: bold;
    /*margin-top: -50px;*/
  }

}

</style>
